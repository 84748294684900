import React from "react";

const GyanConsulting = () => {
  return (
    <div className="gyan-consulting-wrapper">
      {/* <div className="border-line"></div> */}
      <div className="gyan-consulting-wrap">
        <img
          className="big-gyan-consulting"
          src="/images/icons/gyan-consulting.png"
          alt="img"
        />
        <img
          className="small-gyan-consulting"
          src="/images/icons/small-gyan-consulting.png"
          alt="img"
        />
        <button>
          <a href="https://www.gyan.solutions/" target="_blank">
            Talk with our expert
          </a>
        </button>
      </div>
      <img
        className="gyan-consulting-globe"
        src="/images/gyan-consulting-globe.png"
        alt="img"
      />
    </div>
  );
};

export default GyanConsulting;
