import React, { useState, Fragment, useEffect, useRef } from "react";
import arrow from "../../../images/down-arrow.png";
import DatePicker from "react-date-picker";
import { serviceGet, servicePost } from "../../../services/api";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ProductTutModal from "../Modals/ProductTutModal";
import ShippingTutModal from "../Modals/ShippingTutModal";
import GyanConsulting from "./GyanConsulting";

const Shipping = () => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Select Product");
  const productVal = selected == "Select Product" ? "" : selected;

  // Lot dropdown
  const [isLotActive, setIsLotActive] = useState(false);
  const [selectedLot, setSelectedLot] = useState("Select Lots");
  const lot = selectedLot == "Select Lots" ? "" : selectedLot;

  // Distributor dropdown
  const [isDistributorActive, setIsDistributorActive] = useState(false);
  const [selectedDistributor, setSelectedDistributor] =
    useState("Select Distributor");
  const distributor =
    selectedDistributor == "Select Distributor" ? "" : selectedDistributor;

  const [users, setUsers] = useState([]);
  const [shipDate, setShipDate] = useState();
  const [skuNumber, setskuNumber] = useState();
  const [expireDate, setExpireDate] = useState();
  const [shipData, setShipData] = useState({
    amount: null,
    shipper: "",
    trackingNo: "",
  });

  const { amount, shipper, trackingNo } = shipData;

  const history = useHistory();
  const refOne = useRef(null);
  const today = new Date().toISOString().split("T")[0];
  // Access Dom and When Click Outside Close DropDowns...
  useEffect(() => {
    const hideOnClickOutside = (e) => {
      if (refOne.current && !refOne.current.contains(e.target)) {
        setIsActive(false);
        setIsLotActive(false);
        setIsDistributorActive(false);
      }
    };

    document.addEventListener("click", hideOnClickOutside);
    return () => {
      document.removeEventListener("click", hideOnClickOutside);
    };
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setShipData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setSelectedLot("Select Lots");
  }, [selected]);

  // get all products
  const getAllProducts = async () => {
    try {
      const allProducts = await serviceGet("api/allproducts");
      setProducts(allProducts);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  // get all users
  const getAllUsers = async () => {
    try {
      const allusers = await serviceGet("api/getUsers");
      setUsers(allusers);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  // get uniqueProducts..
  const uniqueProducts = products?.reduce((acc, current) => {
    if (!acc[current.product]) {
      acc[current.product] = current;
    }
    return acc;
  }, {});
  const uniqueProduct = Object.values(uniqueProducts);

  // get Selected Product value...
  const selectedProduct = products.filter((data) => {
    return data.product == productVal && data.lot == lot;
  });

  useEffect(() => {
    if (selectedProduct) {
      setExpireDate(
        selectedProduct[0]?.expireDate ? selectedProduct[0]?.expireDate : ""
      );
    }
  }, [lot]);

  // Create Ship
  const createShipHandler = async () => {
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }

    if (formatDate(Date.now()) > formatDate(expireDate)) {
      alert("This Product expired");
      return;
    }

    if (
      productVal == "" ||
      lot == "" ||
      distributor == "" ||
      amount == "" ||
      shipper == "" ||
      shipDate == "" ||
      expireDate == "" ||
      trackingNo == ""
    ) {
      alert("Please fill all Fields");
      return;
    }

    try {
      setIsLoading(true);
      const convertdateformat = new Date(expireDate);
      const convertedDate = convertdateformat.toISOString().slice(0, 10);
      console.log("convertedDate", convertedDate);
      const data = await servicePost("api/createShip", {
        product: productVal,
        organization: distributor,
        skuNumber: skuNumber,
        lot: lot,
        amount: amount,
        shipper: shipper,
        trackingNo: trackingNo,
        shipDate: shipDate,
        expireDate: convertedDate,
        from: "Manufacturer",
        to: "Distributor",
      });

      if (data) {
        history.push("/shiping-history");
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        alert(err.response.data.message);
      } else {
        alert("Something went wrong");
      }
      setIsLoading(false);
    }
  };

  /// HandleKeyPress
  function handleKeyPress(event) {
    const regex = /^[0-9\b]+$/; // allows only numbers and backspace
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  // hadleKey allow only Char
  function handleKeyCharPress(event) {
    const regex = /^[a-zA-Z ]+$/; // allows only characters and space
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  // Increment and Decrement Quantity
  const incrementAmount = () => {
    setShipData((prevData) => ({
      ...prevData,
      amount: Number(prevData.amount) + 1,
    }));
  };

  const decrementAmount = () => {
    if (shipData.amount > 0) {
      setShipData((prevData) => ({
        ...prevData,
        amount: Number(prevData.amount) - 1,
      }));
    }
  };

  return (
    <>
      <ShippingTutModal
        show={showTutorialModal}
        onHide={() => setShowTutorialModal(false)}
      />
      <div className="create-product-main-wraaper shipping-product-main-wrapper">
        <div className="header-main-wrapper header-desktop-main-wrapper">
          <div className="header-main-wrap">
            <div className="header-logo">
              <img src="/images/icons/shipping.png" alt="icon" />
              <h3> Shipping </h3>
            </div>
          </div>
        </div>

        <div className="header-main-wrapper header-mobile-main-wrapper">
          <div className="header-main-wrap">
            <div className="header-logo">
              <img src="/images/icons/shipping.png" alt="icon" />
              <h3> Shipping </h3>
            </div>
          </div>
          <div className="mobile-project-main-logo">
            <img src="/images/icons/manu-small-logo.png" alt="logo" />
          </div>
        </div>

        <div className="mobile-create-tutorial-wrap mobile-shipping-tutorial-wrap">
          <div className="tutorial-wrap-btn">
            <img
              onClick={() => setShowTutorialModal(true)}
              src="/images/icons/tutorial-icon.png"
              alt="icon"
            />
            <p> Tutorials </p>
          </div>
        </div>

        <div className="create-product-main-wrap">
          <div className="create-product-left-wrap">
            <div className="create-product-left">
              <div className="create-product-left-img">
                <img src="/images/shipping-img.png" alt="img" />
              </div>
              <div className="create-product-left-contant">
                {/* <h2> Welcome</h2>
                <h3> to the Shipping Creation Page </h3> */}

                <p>
                  Hyperledger Fabric's 'Shipping' functionality streamlines the
                  process of sending pharmaceutical products. It allows users to
                  select the product, destination distributor, lot, tracking
                  information, quantity, and ship date. By securely recording
                  this data on the blockchain, it ensures compliant and
                  efficient shipments within the pharmaceutical supply chain.
                </p>

                <div className="toutorial-icon-wrap">
                  <img
                    onClick={() => setShowTutorialModal(true)}
                    src="/images/icons/tutorial-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="create-product-right-wrap shipping-product-form">
            <div className="card1">
              <div className="card-body">
                <form>
                  <div className="create-product-form row">
                    <div className="col-sm-12 col-md-6 col-md-6">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Product</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsActive(!isActive);
                              setIsLotActive(false);
                              setIsDistributorActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/input-1.svg"
                                    alt="icon"
                                  />
                                </div>
                                {selected}
                              </p>
                            </div>

                            <img
                              className={
                                isActive
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src={arrow}
                              alt="arrow"
                            />
                          </div>
                          {isActive && (
                            <div className="dropdown-content" ref={refOne}>
                              {uniqueProduct &&
                                uniqueProduct?.map((option, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <div
                                        onClick={(e) => {
                                          setSelected(option.product);
                                          setskuNumber(option.sku);
                                          setIsActive(false);
                                        }}
                                        className="dropdown-item"
                                      >
                                        <p> {option.product} </p>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Shipper
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="shipper"
                          className="input-field-redux"
                          placeholder="Enter Shipper name"
                          onKeyPress={handleKeyCharPress}
                          onChange={onChangeHandler}
                          value={shipper}
                        />

                        <div className="input-icon-wrap">
                          <img
                            src="/images/icons/shipper-input.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Distributor</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsDistributorActive(!isDistributorActive);
                              setIsLotActive(false);
                              setIsActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/dist-input.svg"
                                    alt="icon"
                                  />
                                </div>
                                {selectedDistributor}
                              </p>
                            </div>

                            <img
                              className={
                                isDistributorActive
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src={arrow}
                              alt="arrow"
                            />
                          </div>
                          {isDistributorActive && (
                            <div
                              className="dropdown-content distributor-ref"
                              ref={refOne}
                            >
                              {users &&
                                users
                                  ?.filter((data) => {
                                    return data.organization == "Distributor";
                                  })
                                  .map((option, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <div
                                          onClick={(e) => {
                                            setSelectedDistributor(
                                              option.email
                                            );
                                            setIsDistributorActive(false);
                                          }}
                                          className="dropdown-item"
                                        >
                                          <p> {option.email} </p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        sku number
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="skuNumber"
                          type="number"
                          placeholder="Enter sku number"
                          onKeyPress={handleKeyPress}
                          className="input-field-redux"
                          onChange={onChangeHandler}
                          value={skuNumber}
                          min={0}
                        />
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-5.svg" alt="icon" />
                        </div>
                      </div>
                    </div> */}

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label> Tracking number</label>
                      <div className="create-product-input">
                        <input
                          name="trackingNo"
                          className="input-field-redux"
                          placeholder="Enter Your Text"
                          onChange={onChangeHandler}
                          value={trackingNo}
                        />
                        <div className="input-icon-wrap">
                          <img
                            src="/images/icons/traking-input.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Lots</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsLotActive(!isLotActive);
                              setIsActive(false);
                              setIsDistributorActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/input-1.svg"
                                    alt="icon"
                                  />
                                </div>
                                {productVal
                                  ? selectedLot
                                  : "Select product first"}
                              </p>
                            </div>

                            <img
                              className={
                                isLotActive && productVal
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src={arrow}
                              alt="arrow"
                            />
                          </div>
                          {isLotActive && productVal && (
                            <div className="dropdown-content" ref={refOne}>
                              {products &&
                                products
                                  .filter((data) => {
                                    return data.product == selected;
                                  })
                                  .map((option, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <div
                                          onClick={(e) => {
                                            setSelectedLot(option.lot);
                                            setIsLotActive(false);
                                          }}
                                          className="dropdown-item"
                                        >
                                          <p>{option.lot}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Quantity
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input quantity-input-wrap">
                        <input
                          name="amount"
                          className="input-field-redux"
                          type="number"
                          onKeyPress={handleKeyPress}
                          onChange={onChangeHandler}
                          value={amount}
                          min={0}
                        />
                        <div className="increment-icon">
                          <img
                            onClick={incrementAmount}
                            src="/images/icons/increment-icon.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="decrement-icon">
                          <img
                            onClick={decrementAmount}
                            src="/images/icons/decrement-icon.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-6.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Ship date
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <input
                        type="date"
                        min={today}
                        onChange={(e) => setShipDate(e.target.value)}
                      />
                    </div>

                    {expireDate ? (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label>
                          Expire date
                          <img src="/images/icons/info-icon.svg" alt="icon" />
                        </label>
                        <input
                          type="date"
                          name="expire date"
                          className="input-field-redux expire-date-disable"
                          value={
                            expireDate
                              ? moment(expireDate).format("YYYY-MM-DD")
                              : ""
                          }
                          disabled
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label> Expire date </label>
                        <div className="create-product-input">
                          <input
                            className="input-field-redux"
                            value="Select Lot first"
                          />
                          <div className="input-icon-wrap">
                            <img src="/images/icons/input-8.svg" alt="icon" />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-sm-12 text-center shipping-tutorail-button-wrapper">
                      <button
                        className="create-product-btn"
                        onClick={createShipHandler}
                        disabled={isLoading}
                      >
                        {isLoading ? "Processing..." : "Proceed"}
                      </button>
                      <div className="tutorial-wrap-btn tutorial-tab-etc">
                        <img
                          onClick={() => setShowTutorialModal(true)}
                          src="/images/icons/tutorial-icon.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GyanConsulting />
    </>
  );
};

export default Shipping;
