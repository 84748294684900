import { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
/// Components
import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import { isUserAuthenticated } from "./services/authUtils";
/// Style
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
// import "./vendor/datatables/css/dataTables.min.css";
import "./css/style.css";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function App(props) {
  const history = useHistory();
  const authToken = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isUserAuthenticated()) {
      if (history.location.pathname === "/login") {
        history.push("/dashboard");
      }
    } else {
      history.push("/login");
    }
  }, [dispatch, props.history]);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
    </Switch>
  );
  if (isUserAuthenticated()) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

export default App;
