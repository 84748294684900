import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

///Images
import pic1 from "./../../../images/avatar/1.jpg";
import pic2 from "./../../../images/avatar/2.jpg";
import pic3 from "./../../../images/avatar/3.jpg";
import pic4 from "./../../../images/avatar/4.jpg";

//Import
import { ThemeContext } from "../../../context/ThemeContext";
import RadialDount from "./Dashboard/RadialDount";
import ReservationChart from "./Dashboard/ReservationChart";
import LatestCustomer from "./Dashboard/LatestCustomer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GyanConsulting from "./GyanConsulting";
import ExplorerTutModal from "../Modals/ExplorerTutModal";
import LoginTabs from "./LoginTabs";
import LoginTutModal from "../Modals/LoginTutModal";
const AnalyticsDonut = loadable(() =>
  pMinDelay(import("./Dashboard/AnalyticsDonut"), 1000)
);

const Home = () => {
  const [showExTutorialModal, setShowExTutorialModal] = useState(false);
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);
  const [value, onChange] = useState(new Date());

  const [activeTab, setActiveTab] = useState("");

  const tabContentRef = useRef(null);

  const openCity = (cityName) => {
    setActiveTab(cityName);
  };

  const clearActiveTab = () => {
    setActiveTab(null); // Set activeTab to null to clear the active tab
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tabContentRef.current &&
        !tabContentRef.current.contains(event.target)
      ) {
        setActiveTab(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const history = useHistory();
  function onLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  }
///////////////
useEffect(() =>  {
  // props.onHide();
 
    const tutorials = localStorage.getItem("logintutorial");
   
    if (tutorials == null) {
      localStorage.setItem("logintutorial", true);
      setShowTutorialModal(true);
    } else {
      setShowTutorialModal(false);
    }

    // setShowShipHistoryModal(true);
  
  },[]);

////////////////
  return (
    <>
      <ExplorerTutModal
        show={showExTutorialModal}
        onHide={() => setShowExTutorialModal(false)}
      />
 <LoginTutModal
        show={showTutorialModal}
        onHide={() => setShowTutorialModal(false)}
        //token={token}
       // userData={userData}
      />
      <div className="header-main-wrapper header-desktop-main-wrapper">
        <div className="header-main-wrap">
          <div className="header-logo">
            <img src="/images/icons/dashbord-head.png" alt="icon" />
            <h3> Dashboard </h3>
          </div>

          <div className="header-logo-right">
            <img
              onClick={() => setShowExTutorialModal(true)}
              className="hyper-header-logo"
              src="/images/icons/explorer-logo.png"
              alt="logo"
            />

            <img
              onClick={() => setShowExTutorialModal(true)}
              src="/images/icons/explorer-arrow.svg"
              alt="logo"
            />
          </div>
        </div>
      </div>

      <div className="header-main-wrapper header-mobile-main-wrapper">
        <div className="header-main-wrap">
          <div className="header-logo">
            <img src="/images/icons/dashbord-head.png" alt="icon" />
            <h3> Dashboard </h3>
          </div>
        </div>
        <div className="mobile-project-main-logo">
          <img src="/images/icons/manu-small-logo.png" alt="logo" />
        </div>
      </div>

      <div className="dashboard-card-wrapper">
        <div className="row">
          <div className="col-xl-3 col-sm-6">
            <div className="card gradient-1 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">150</h2>
                  <span className="fs-18"> New Orders </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="card gradient-2 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white"> 285 </h2>
                  <span className="fs-18">Total Orders</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="card gradient-3 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">135</h2>
                  <span className="fs-18">Completed Orders</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="card gradient-4 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">65</h2>
                  <span className="fs-18"> Ship Orders </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-xxl-4">
            <div className="row">
              <div className="col-xl-12 col-sm-6">
                <div className="card text-center">
                  <div className="card-body">
                    <div id="radialChart" className="radialChart">
                      <RadialDount />
                      <h2> 70 </h2>
                    </div>

                    <span className="fs-16 text-black"> Pending Orders </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-sm-6">
                <div className="card">
                  <div className="card-header border-0 pb-0">
                    <h4 className="mb-0"> Today </h4>
                  </div>
                  <div className="card-body">
                    <div className="progress mb-4" style={{ height: "35px" }}>
                      <div
                        className="progress-bar gradient-5 progress-animated"
                        style={{ width: "55%", height: "35px" }}
                      >
                        <span className="sr-only">45% Complete</span>
                      </div>
                    </div>
                    <div className="progress mb-4" style={{ height: "35px" }}>
                      <div
                        className="progress-bar gradient-6 progress-animated"
                        style={{ width: "55%", height: "35px" }}
                      >
                        <span className="sr-only">45% Complete</span>
                      </div>
                    </div>
                    {/* <div
                      className="progress default-progress"
                      style={{ height: "13px" }}
                    >
                      <div
                        className="progress-bar gradient-7 progress-animated"
                        style={{ width: "55%", height: "13px" }}
                      >
                        <span className="sr-only">45% Complete</span>
                      </div>
                    </div> */}
                    <div className="d-flex mt-4 progress-bar-legend align-items-center justify-content-between">
                      <div className="d-flex">
                        <span className="marker gradient-5"></span>
                        <div className="status-bar-order-wrap">
                          <p className="status"> complete orders: </p>
                          <span className="result">135</span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <span className="marker gradient-6"></span>
                        <div className="status-bar-order-wrap">
                          <p className="status">Shipping Orders:</p>
                          <span className="result">65</span>
                        </div>
                      </div>
                      {/* <div className="d-flex">
                        <span className="marker gradient-7"></span>
                        <div>
                          <p className="status">Finish</p>
                          <span className="result">763</span>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-xxl-8">
            <div className="card">
              <div className="card-header border-0 d-sm-flex d-block">
                <div className="me-auto mb-sm-0 mb-3">
                  <h4 className="card-title mb-2"> Statistic</h4>
                  {/* <span>Lorem ipsum dolor sit amet</span> */}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex me-5">
                    <h3 className="mb-0 me-2">549</h3>
                    <span>Check In</span>
                  </div>
                  <div className="d-flex me-3">
                    <h3 className="mb-0 me-2">327</h3>
                    <span>Check Out</span>
                  </div>
                  {/* <Dropdown className="dropdown">
                  <Dropdown.Toggle as="div" className="btn-link i-false">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                        stroke="#575757"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                        stroke="#575757"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                        stroke="#575757"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <Dropdown.Item className="dropdown-item">
                      Delete
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item">
                      Edit
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                </div>
              </div>
            </div>
            <div className="card-body">
              <div id="reservationChart" className="reservationChart">
                <ReservationChart />
              </div>
            </div>

            <LoginTabs />
          </div>
        </div>
      </div>

      <GyanConsulting />
    </>
  );
};
export default Home;
