import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LoginTutModal = (props) => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleProceedClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const history = useHistory();

  const setLocalStorageData = () => {
    // localStorage.setItem("token", props.token);
    // localStorage.setItem("userData", props.userData);
    // history.push("/dashboard");
    props.onHide();
  //  window.location.reload();
  };

  return (
    <>
      <Modal
        {...props}
        dialogClassName="login-tutorial-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="login-tutorial-main-wrapper">
            <div className="login-tutorial-main-wrap">
              <div className="close-drawer-wrap">
                <img
                  onClick={()=> props.onHide()}
                  src="/images/icons/cross-icon.svg"
                />
              </div>
              {currentStep === 1 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-wrap">
                      <div className="login-manu-logo">
                        <img src="/images/icons/manu-logo.png" alt="logo" />
                      </div>
                      <div className="login-tutorail-contant-wrap login-first-content">
                        <h3> Welcome to the Manufacturer ERP </h3>
                        <p>
                          Participation in a blockchain-based application
                          benefits pharmaceutical manufacturers by bolstering
                          compliance with quality control regulations, such as
                          Good Manufacturing Practices (GMP) and Good Clinical
                          Practices (GCP). This technology securely records
                          product movements and transactions, ensuring real-time
                          visibility into the supply chain. Manufacturers can
                          efficiently identify and manage affected batches
                          during recalls, reducing risks and safeguarding their
                          reputation. <br /> <br /> Automated compliance
                          processes streamline regulatory adherence, while
                          blockchain's data security safeguards sensitive
                          intellectual property. This commitment to quality and
                          transparency fosters trust among stakeholders,
                          enabling manufacturers to deliver safe, authentic
                          products while achieving operational excellence in the
                          pharmaceutical industry.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-wrap">
                      <div className="login-manu-logo">
                        <img src="/images/icons/manu-logo.png" alt="logo" />
                      </div>
                      <div className="login-tutorail-contant-wrap">
                        <h3> Welcome to the Manufacturer ERP </h3>
                        <p>
                          Participation in a blockchain-based application
                          benefits pharmaceutical manufacturers by bolstering
                          compliance with quality control regulations, such as
                          Good Manufacturing Practices (GMP) and Good Clinical
                          Practices (GCP). This technology securely records
                          product movements and transactions, ensuring real-time
                          visibility into the supply chain. Manufacturers can
                          efficiently identify and manage affected batches
                          during recalls, reducing risks and safeguarding their
                          reputation. <br /> <br /> Automated compliance
                          processes streamline regulatory adherence, while
                          blockchain's data security safeguards sensitive
                          intellectual property. This commitment to quality and
                          transparency fosters trust among stakeholders,
                          enabling manufacturers to deliver safe, authentic
                          products while achieving operational excellence in the
                          pharmaceutical industry.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Second Step */}
              {currentStep === 2 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Dasboard </h3>
                        <p>
                          Hyperledger serves as digital glue, linking software
                          systems like a bridge between islands. It quietly
                          maintains your ERP system as the central business hub,
                          enabling secure data flow among systems, fostering
                          collaboration without major adjustments or additional
                          apps.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/dashboard-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Dasboard </h3>
                        <p>
                          Hyperledger serves as digital glue, linking software
                          systems like a bridge between islands. It quietly
                          maintains your ERP system as the central business hub,
                          enabling secure data flow among systems, fostering
                          collaboration without major adjustments or additional
                          apps.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img
                          src="/images/mobile-dashboard-demo.png"
                          alt="img"
                        />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>

                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Third Step */}
              {currentStep === 3 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Create Product </h3>
                        <p>
                          In pharmaceutical manufacturing, record product
                          details securely on Hyperledger Fabric blockchain,
                          ensuring tamper-proof data. Benefits: regulatory
                          compliance and enhanced traceability, boosting patient
                          safety and product authenticity.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/create-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-3"> 3 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Create Product </h3>
                        <p>
                          In pharmaceutical manufacturing, record product
                          details securely on Hyperledger Fabric blockchain,
                          ensuring tamper-proof data. Benefits: regulatory
                          compliance and enhanced traceability, boosting patient
                          safety and product authenticity.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/mobile-create-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-3"> 3 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Forth Step */}
              {currentStep === 4 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Product List </h3>
                        <p>
                          Hyperledger Fabric ensures meticulous recording of
                          pharmaceutical product data on a tamper-proof
                          blockchain, offering invaluable transparency,
                          traceability, regulatory compliance, and patient
                          safety enhancements in the supply chain.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/product-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-4"> 4 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Product List </h3>
                        <p>
                          Hyperledger Fabric ensures meticulous recording of
                          pharmaceutical product data on a tamper-proof
                          blockchain, offering invaluable transparency,
                          traceability, regulatory compliance, and patient
                          safety enhancements in the supply chain.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/mobile-product-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-4"> 4 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Fifth Step */}
              {currentStep === 5 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Shipping </h3>
                        <p>
                          Hyperledger Fabric revolutionizes pharmaceutical
                          logistics with secure, transparent blockchain records.
                          It streamlines processes, enhances compliance, reduces
                          risks, and ensures authenticity and integrity,
                          benefiting manufacturers, distributors, and end-users.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/shipping-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-5"> 5 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Shipping </h3>
                        <p>
                          Hyperledger Fabric revolutionizes pharmaceutical
                          logistics with secure, transparent blockchain records.
                          It streamlines processes, enhances compliance, reduces
                          risks, and ensures authenticity and integrity,
                          benefiting manufacturers, distributors, and end-users.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/mobile-shipping-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-5"> 5 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Sixth Step */}
              {currentStep === 6 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Shipping History </h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof shipping records, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          logistics, fostering unwavering data integrity and
                          trust.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img
                          src="/images/shipping-history-demo.png"
                          alt="img"
                        />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-6"> 6 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Shipping History </h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof shipping records, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          logistics, fostering unwavering data integrity and
                          trust.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img
                          src="/images/mobile-shipping-history-demo.png"
                          alt="img"
                        />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-6"> 6 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Seven Step */}
              {currentStep === 7 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Explorer </h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof shipping records, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          logistics, fostering unwavering data integrity and
                          trust.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/explorer-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button
                            className="go-dashboard"
                            onClick={setLocalStorageData}
                          >
                            Go to Dashboard
                          </button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-7"> 7 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div className="dots-wrap active"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="login-tutorail-contant-wrap">
                        <h3> Explorer </h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof shipping records, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          logistics, fostering unwavering data integrity and
                          trust.
                        </p>
                      </div>

                      <div className="login-tutorial-demo">
                        <img src="/images/mobile-explore-demo.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button">
                          <button
                            className="go-dashboard"
                            onClick={setLocalStorageData}
                          >
                            Go to Dashboard
                          </button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-7"> 7 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div className="dots-wrap active"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginTutModal;
