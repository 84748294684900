import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./ExplorerModal.css";

const ExplorerTutModal = (props) => {
  const [copyMsg, setCopyMsg] = useState("Copy");
  const [tooltipVisible, setTooltipVisible] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const handleProceedClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const history = useHistory();

  const setLocalStorageData = () => {
    localStorage.setItem("token", props.token);
    localStorage.setItem("userData", props.userData);
    history.push("/dashboard");
    props.onHide();
    window.location.reload();
  };

  const handleCopyWalletId = (walletId, dataIndex) => {
    navigator.clipboard.writeText(walletId);
    setTooltipVisible({ [dataIndex]: true });
    setCopyMsg("Copied");

    // Hide the tooltip after a short delay
    setTimeout(() => {
      setTooltipVisible({ [dataIndex]: false });
      setCopyMsg("Copy");
    }, 1000);
  };

  return (
    <>
      <Modal
        {...props}
        dialogClassName="explorer-tutorial-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="explorer-tutorial-main-wrapper">
            <div className="explore-tutorial-main-wrap">
              <div className="close-drawer-wrap">
                <img
                  onClick={() => props.onHide()}
                  src="/images/icons/exp-cross.svg"
                />
              </div>
              {currentStep === 1 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="explorer-tutorial-wrap">
                      <div className="explorer-manu-logo">
                        <img src="/images/icons/exp-logo.png" alt="logo" />
                      </div>
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Welcome to the Hyperledger Explorer CRM </h3>
                        <p>
                          Hyperledger Explorer is an essential tool for managing
                          and monitoring your Hyperledger Fabric blockchain
                          network. It provides a user-friendly web interface
                          that allows you to visualize and interact with the
                          components of your blockchain network. Whether you're
                          a developer, network administrator, or an enthusiast
                          looking to explore the inner workings of a blockchain,
                          Hyperledger Explorer is a valuable resource.
                        </p>
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="exp-dots-wrap active"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="explorer-tutorial-wrap">
                      <div className="explorer-manu-logo">
                        <img src="/images/icons/exp-logo.png" alt="logo" />
                      </div>
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Welcome to the Hyperledger Explorer CRM </h3>
                        <p>
                          Hyperledger Explorer is an essential tool for managing
                          and monitoring your Hyperledger Fabric blockchain
                          network. It provides a user-friendly web interface
                          that allows you to visualize and interact with the
                          components of your blockchain network. Whether you're
                          a developer, network administrator, or an enthusiast
                          looking to explore the inner workings of a blockchain,
                          Hyperledger Explorer is a valuable resource.{" "}
                        </p>
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="exp-dots-wrap active"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                              <div className="exp-dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Second Step */}
              {currentStep === 2 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Login Credentials </h3>
                        <p>
                          Copy and securely note your username and password for
                          access to Admin Explorer, a portal for administrative
                          features and data management.
                          <br />
                          <span className="admin-password">
                            User: exploreradmin
                            <span tooltip={copyMsg} flow="up">
                              <img
                                onClick={() =>
                                  handleCopyWalletId("exploreradmin", 1)
                                }
                                src="./images/icons/exp-cross-copy.svg"
                                alt="icon"
                              />
                            </span>
                          </span>
                          <span className="admin-password">
                            Password: exploreradminpw
                            <span tooltip={copyMsg} flow="up">
                              <img
                                onClick={() =>
                                  handleCopyWalletId("exploreradminpw", 1)
                                }
                                src="./images/icons/exp-cross-copy.svg"
                                alt="icon"
                              />
                            </span>
                          </span>
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/exp-1.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Login Credentials </h3>
                        <p>
                          Copy and securely note your username and password for
                          access to Admin Explorer, a portal for administrative
                          features and data management.
                          <br />
                          <br />
                          <span className="admin-password">
                            User: exploreradmin
                            <span tooltip={copyMsg} flow="up">
                              <img
                                onClick={() =>
                                  handleCopyWalletId("exploreradmin", 1)
                                }
                                src="./images/icons/exp-cross-copy.svg"
                                alt="icon"
                              />
                            </span>
                          </span>
                          <br />
                          <span className="admin-password">
                            Password: exploreradminpw
                            <span tooltip={copyMsg} flow="up">
                              <img
                                onClick={() =>
                                  handleCopyWalletId("exploreradminpw", 1)
                                }
                                src="./images/icons/exp-cross-copy.svg"
                                alt="icon"
                              />
                            </span>
                          </span>
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/mobile-exp-1.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Second Step */}
              {currentStep === 3 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Dasboard </h3>
                        <p>
                          Upon logging in, users are presented with a
                          comprehensive DASHBOARD that offers an overview of the
                          blockchain network's current status and key
                          performance indicators. This visual summary includes
                          important network statistics and transaction details.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/exp-2.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-3"> 3 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>

                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Dasboard </h3>
                        <p>
                          Upon logging in, users are presented with a
                          comprehensive DASHBOARD that offers an overview of the
                          blockchain network's current status and key
                          performance indicators. This visual summary includes
                          important network statistics and transaction details.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/mobile-exp-2.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-3"> 3 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>

                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Third Step */}
              {currentStep === 4 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Network </h3>
                        <p>
                          The NETWORK section provides an in-depth view of the
                          blockchain network's structure. Users can explore the
                          network's organizations, peers, orderers, and other
                          vital components. This section offers valuable
                          insights into the network's architecture.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/exp-3.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-4"> 4 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Network </h3>
                        <p>
                          The NETWORK section provides an in-depth view of the
                          blockchain network's structure. Users can explore the
                          network's organizations, peers, orderers, and other
                          vital components. This section offers valuable
                          insights into the network's architecture.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/mobile-exp-3.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-4"> 4 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Forth Step */}
              {currentStep === 5 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Blocks </h3>
                        <p>
                          In the BLOCKS section, users can examine individual
                          blocks on the blockchain. Each block contains a set of
                          transactions, and users can access details about each
                          block's content, including transaction timestamps and
                          data.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/exp-4.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-5"> 5 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Blocks </h3>
                        <p>
                          In the BLOCKS section, users can examine individual
                          blocks on the blockchain. Each block contains a set of
                          transactions, and users can access details about each
                          block's content, including transaction timestamps and
                          data.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/mobile-exp-4.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-5"> 5 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Fifth Step */}
              {currentStep === 6 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Transaction </h3>
                        <p>
                          The TRANSACTIONS section allows users to explore and
                          analyze individual transactions within the blockchain.
                          Users can review transaction details, participant
                          information, and the cryptographic hash that uniquely
                          identifies each transaction.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/exp-5.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-6"> 6 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Transaction </h3>
                        <p>
                          The TRANSACTIONS section allows users to explore and
                          analyze individual transactions within the blockchain.
                          Users can review transaction details, participant
                          information, and the cryptographic hash that uniquely
                          identifies each transaction.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/mobile-exp-5.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-6"> 6 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Sixth Step */}
              {currentStep === 7 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Chaincodes </h3>
                        <p>
                          The CHAINCODES section provides insights into the
                          smart contracts (chaincodes) deployed on the
                          blockchain network. Users can view information about
                          deployed chaincodes, their versions, and associated
                          transactions.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/exp-6.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-7"> 7 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>

                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Chaincodes </h3>
                        <p>
                          The CHAINCODES section provides insights into the
                          smart contracts (chaincodes) deployed on the
                          blockchain network. Users can view information about
                          deployed chaincodes, their versions, and associated
                          transactions.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/mobile-exp-6.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-7"> 7 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>

                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(8)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Seven Step */}
              {currentStep === 8 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Channels </h3>
                        <p>
                          In the CHANNELS section, users can examine the various
                          channels within the blockchain network. Channels
                          enable private communication between specific
                          participants. Users can access information about
                          channel configurations and transactions specific to
                          each channel.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/exp-7.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button className="go-dashboard">
                            <a
                              href="https://hyperledgerexplorer.gyan.solutions"
                              target="_blank"
                            >
                              Go to Dashboard
                            </a>
                          </button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-8"> 8 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="explorer-tutorial-second-wrap">
                      <div className="explorer-tutorail-contant-wrap">
                        <h3> Explorer </h3>
                        <p>
                          Hyperledger Fabric ensures secure, transparent,
                          tamper-proof shipping records, enhancing compliance,
                          authenticity, and patient safety in pharmaceutical
                          logistics, fostering unwavering data integrity and
                          trust.
                        </p>
                      </div>

                      <div className="explorer-tutorial-demo">
                        <img src="/images/mobile-exp-7.png" alt="img" />
                      </div>

                      <div className="explorer-tutorial-bottom-wrap">
                        <div className="explorer-continue-button">
                          <button className="go-dashboard">
                            <a
                              href="https://hyperledgerexplorer.gyan.solutions"
                              target="_blank"
                            >
                              Go to Dashboard
                            </a>
                          </button>
                        </div>

                        <div className="explorer-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-8"> 8 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(2)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="exp-dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                              <div className="exp-dots-wrap active"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExplorerTutModal;
