// @flow
import jwtDecode from "jwt-decode";
/**
 * Checks if token is authenticated
 */

const AuthUtils = async () => {};

export default AuthUtils;

/**
 * Returns the logged in token
 */
const getLoggedUser = () => {
  const token = localStorage.getItem("token");
  return token;
};

const isUserAuthenticated = () => {
  const token = getLoggedUser();
  if (!token) {
    return false;
  }
  if (token != undefined) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // console.warn("access token expired");
      return false;
    } else {
      return true;
    }
  }
};

export { isUserAuthenticated, getLoggedUser };
