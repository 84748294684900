import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const ProductTutModal = (props) => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleProceedClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const producttutorial = () => {
    try {
      localStorage.setItem("producttutorial", true);
      props.onHide();

      /// }
    } catch (err) {}
  };

  return (
    <>
      <Modal
        {...props}
        dialogClassName="login-tutorial-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="login-tutorial-main-wrapper">
            <div className="login-tutorial-main-wrap">
              {currentStep === 1 ? (
                <div className="product-close-btn">
                  <div className="close-drawer-wrap product-close-drawer">
                    <img
                      onClick={producttutorial}
                      src="/images/icons/cross-icon.svg"
                    />
                  </div>
                </div>
              ) : (
                <div className="close-drawer-wrap">
                  <img
                    onClick={producttutorial}
                    src="/images/icons/cross-icon.svg"
                  />
                </div>
              )}

              {currentStep === 1 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="product-tutorial-wrap product-tut-first-wrap">
                      <div className="product-tut-image">
                        <img
                          className="product-tut-first-img"
                          src="/images/product-tut-1.png"
                          alt="logo"
                        />
                      </div>
                      <div className="login-tutorail-contant-wrap product-tutorail-contant-wrap">
                        <h3> Welcome to the Create Product Page </h3>
                        <p>
                          You have the capability to create a new product or
                          generate a new lot for an existing product using the
                          Hyperledger Fabric blockchain. This process safeguards
                          the authenticity of product information, ensuring its
                          permanence and the unalterable integrity of data.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="product-tutorial-wrap">
                      {/* <div className="product-tutorial-demo">
                        <img src="/images/mobile-product-tut-1.png" alt="img" />
                      </div> */}

                      <div className="login-tutorail-contant-wrap mobile-product-first-wrap">
                        <h3> Welcome to the Create Product Page </h3>
                        <p>
                          You have the capability to create a new product or
                          generate a new lot for an existing product using the
                          Hyperledger Fabric blockchain. This process safeguards
                          the authenticity of product information, ensuring its
                          permanence and the unalterable integrity of data.
                        </p>
                      </div>

                      <div className="product-tut-image">
                        <img
                          src="/images/mobile-product-tut-1.png"
                          alt="logo"
                        />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Second Step */}
              {currentStep === 2 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/product-tut-2.png" alt="img" />
                      </div>
                      {/* <div className="product-tutorial-demo">
                        <div className="product-demo-left">
                          <div className="create-product-head">
                            <h3> Understanding the Input Fields </h3>
                            <p> Start with the Basics </p>
                          </div>
                          <div className="create-product-tut-left-wrapper">
                            <div className="create-product-step">
                              <div className="create-product-icon">
                                <img
                                  src="./images/icons/input-1.svg"
                                  alt="icon"
                                />
                              </div>
                              <div className="create-product-step-content">
                                <h3> Product Name </h3>
                                <p>
                                  'Product Name' is securely recorded on the
                                  Hyperledger Fabric blockchain. This ensures an
                                  unalterable record, enhancing product
                                  traceability and authenticity.
                                </p>
                              </div>
                            </div>

                            <div className="create-product-step">
                              <div className="create-product-icon">
                                <img
                                  src="./images/icons/input-3.svg"
                                  alt="icon"
                                />
                              </div>
                              <div className="create-product-step-content">
                                <h3> Manufacturer </h3>
                                <p>
                                  "Choosing the manufacturer is transparently
                                  recorded on the Hyperledger Fabric blockchain,
                                  safeguarding this data and providing clarity
                                  about the manufacturing source.
                                </p>
                              </div>
                            </div>

                            <div className="create-product-step">
                              <div className="create-product-icon">
                                <img
                                  src="./images/icons/input-5.svg"
                                  alt="icon"
                                />
                              </div>
                              <div className="create-product-step-content">
                                <h3> SKU Number </h3>
                                <p>
                                  SKU details are cataloged securely on the
                                  Hyperledger Fabric blockchain, streamlining
                                  inventory management and ensuring data
                                  accuracy.
                                </p>
                              </div>
                            </div>

                            <div className="create-product-step">
                              <div className="create-product-icon">
                                <img
                                  src="./images/icons/input-7.svg"
                                  alt="icon"
                                />
                              </div>
                              <div className="create-product-step-content">
                                <h3> GTIN #</h3>
                                <p>
                                  Coding GTINs (Global Trade Item Numbers) on
                                  the Hyperledger Fabric blockchain safeguards
                                  the authenticity of GTIN information, ensuring
                                  its permanence and the unalterable integrity
                                  of product data.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="create-product-tut-right-wrapper">
                          <img src="/images/product-tut-2.png" alt="img" />
                        </div>
                      </div> */}

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-product-tut-2.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(6)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(7)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Third Step */}
              {currentStep === 3 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/product-tut-3.png" alt="img" />
                      </div>
                      {/* <div className="product-tutorial-demo">
                        <div className="product-demo-left">
                          <div className="create-product-head">
                            <h3> Understanding the Input Fields </h3>
                            <p> Start with the Basics </p>
                          </div>
                          <div className="create-product-tut-left-wrapper">
                            <div className="create-product-step lot-product-step">
                              <div className="create-product-icon">
                                <img
                                  src="./images/icons/input-1.svg"
                                  alt="icon"
                                />
                              </div>
                              <div className="create-product-step-content">
                                <h3> Lot Number </h3>
                                <p>
                                  Lot numbers, recorded on Hyperledger Fabric,
                                  efficiently track product batches, enabling
                                  swift recalls and enhancing supply chain
                                  transparency. They serve as unique identifiers
                                  for pharmaceutical lots.
                                </p>
                              </div>
                            </div>

                            <div className="create-product-step lot-product-step">
                              <div className="create-product-icon">
                                <img
                                  src="./images/icons/input-4.svg"
                                  alt="icon"
                                />
                              </div>
                              <div className="create-product-step-content">
                                <h3> Serial Number </h3>
                                <p>
                                  Serial numbers on Hyperledger Fabric provide
                                  precise tracking for individual product units,
                                  maintaining data integrity throughout the
                                  supply chain. Each serial number ensures
                                  granular visibility and accountability.
                                </p>
                              </div>
                            </div>

                            <div className="create-product-step lot-product-step">
                              <div className="create-product-icon">
                                <img
                                  src="./images/icons/input-6.svg"
                                  alt="icon"
                                />
                              </div>
                              <div className="create-product-step-content">
                                <h3> Quantity </h3>
                                <p>
                                  Hyperledger Fabric's 'Quantity' field offers
                                  real-time inventory transparency for product
                                  batches, aiding in efficient supply chain
                                  management. It enables quick access to the
                                  precise quantity of products within each
                                  batch.
                                </p>
                              </div>
                            </div>

                            <div className="create-product-step lot-product-step">
                              <div className="create-product-icon">
                                <img
                                  src="./images/icons/expire-date-input.png"
                                  alt="icon"
                                />
                              </div>
                              <div className="create-product-step-content">
                                <h3> Expiry Date </h3>
                                <p>
                                  The 'Expiry Date' field, managed by smart
                                  contracts on Hyperledger Fabric, enforces
                                  product expiration restrictions, preventing
                                  the transfer of expired items. This ensures
                                  compliance, patient safety, and regulatory
                                  adherence.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="create-product-tut-right-wrapper">
                          <img src="/images/product-tut-3.png" alt="img" />
                        </div>
                      </div> */}

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-3"> 3 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(6)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(7)}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-product-tut-3.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-3"> 3 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(6)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(7)}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Forth Step */}
              {currentStep === 4 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/product-tut-4.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>
                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-4"> 4 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(6)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(7)}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-product-tut-4.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>
                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-4"> 4 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(6)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(7)}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Fifth Step */}
              {currentStep === 5 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/product-tut-5.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>
                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-5"> 5 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div className="dots-wrap active"></div>

                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(6)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(7)}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-product-tut-5.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>
                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-5"> 5 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div className="dots-wrap active"></div>

                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(6)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(7)}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Sixth Step */}
              {currentStep === 6 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/product-tut-6.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>
                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-6"> 6 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div className="dots-wrap active"></div>

                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(7)}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-product-tut-6.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>
                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-6"> 6 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div className="dots-wrap active"></div>

                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(7)}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Fifth Step */}
              {currentStep === 7 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/product-tut-7.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button
                            className="create-new-btn"
                            onClick={producttutorial}
                          >
                            <img
                              src="/images/icons/plus-white.svg"
                              alt="icon"
                            />
                            Create Product
                          </button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-7"> 7 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(6)}
                            ></div>
                            <div className="dots-wrap active"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-product-tut-7.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button
                            className="create-new-btn"
                            onClick={producttutorial}
                          >
                            <img
                              src="/images/icons/plus-white.svg"
                              alt="icon"
                            />
                            Create Product
                          </button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-7"> 7 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(6)}
                            ></div>
                            <div className="dots-wrap active"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductTutModal;
