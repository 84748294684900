/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useLocation } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import ProductListTutModal from "../../components/Modals/ProductListTutModal";
import ShippingTutModal from "../../components/Modals/ShippingTutModal";
import ShipHistoryTutModal from "../../components/Modals/ShipHistoryTutModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withRouter } from "react-router-dom";
import ProductTutModal from "../../components/Modals/ProductTutModal";

import ExplorerTutModal from "../../components/Modals/ExplorerTutModal";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const [showExTutorialModal, setShowExTutorialModal] = useState(false);
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [showShipHistoryModal, setShowShipHistoryModal] = useState(false);
  const location = useLocation();
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  /// Path
  let path = location.pathname;

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);

    // Add a listener to detect route changes
    const unlisten = props.history.listen(() => {
      // Remove the 'menu-toggle' class when the route changes
      aaa.classList.remove("menu-toggle");
    });

    // Clean up the listener when the component unmounts
    return () => {
      btn.removeEventListener("click", toggleFunc);
      handleheartBlast.removeEventListener("click", heartBlast);
      unlisten(); // Clean up the route change listener
    };
  }, [props.history]);
  let scrollPosition = useScrollPosition();

  const history = useHistory();
  function onLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  }
  ///////////////
  const shiptutorial = () => {
    // props.onHide();
    try {
      const tutorials = localStorage.getItem("shiptutorial");
      console.log("tutorials", tutorials);
      if (tutorials == null) {
        setShowShipHistoryModal(true);
      } else {
        setShowShipHistoryModal(false);
      }

      // setShowShipHistoryModal(true);
    } catch (err) {}
  };

  ////////////////

  ///////////////
  const productutorial = () => {
    // props.onHide();
    try {
      const tutorials = localStorage.getItem("producttutorial");
      console.log("producttutorial", tutorials);
      if (tutorials == null) {
        setShowTutorialModal(true);
      } else {
        setShowTutorialModal(false);
      }

      // setShowShipHistoryModal(true);
    } catch (err) {}
  };

  ////////////////
  // const shipTutorail = localStorage.getItem("shipTutorailShow");

  // const IsShipHistoryTunShow = () => {
  //   localStorage.setItem("shipTutorailShow", true);
  //   if (shipTutorail) {
  //     setShowShipHistoryModal(false);
  //   } else {
  //     setShowShipHistoryModal(true);
  //   }
  // };

  // path = path.split("/");
  // path = path[path.length - 1];

  /// Active menu
  let deshBoard = [
      "/",
      // "create-product",
      // "Shipping",
      // "inventory",
      // "shiping-history",
    ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    redux = ["redux-form", "redux-wizard", "todo"],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];

  return (
    <>
      <ExplorerTutModal
        show={showExTutorialModal}
        onHide={() => setShowExTutorialModal(false)}
      />
      {/* <ProductListTutModal show={showTutorialModal} onHide={productutorial} />
      <ShipHistoryTutModal show={showShipHistoryModal} onHide={shiptutorial} /> */}

      <ProductTutModal show={showTutorialModal} onHide={productutorial} />

      <ShippingTutModal show={showShipHistoryModal} onHide={shiptutorial} />

      <div
        className={`deznav ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? scrollPosition > 120
              ? "fixed"
              : ""
            : ""
        }`}
      >
        <PerfectScrollbar className="deznav-scroll">
          <div className="sidebar-menu-wrapper">
            <div className="sidebar-logo-wrapper sidebar-nav-logo-border">
              {/* <img
                className="hyper-big-logo"
                src="/images/icons/manu-logo.png"
                alt="logo"
              /> */}
              <img
                className="manu-small-logo"
                src="/images/icons/manu-small-logo.png"
                alt="logo"
              />
            </div>

            <div className="desktop-sibar-header-line"></div>

            <MM className="metismenu" id="menu">
              <li
                className={`${
                  path === "/" || path === "/dashboard" ? "mm-active" : ""
                }`}
              >
                <Link className="has-arrow ai-icon" to="/">
                  {path === "/" || path === "/dashboard" ? (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/dashbord-icon.svg"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/dashbord-gray.svg"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">Dashboard</span>
                </Link>
              </li>
              <li
                className={`${path == "/create-product" ? "mm-active" : ""}`}
                onClick={productutorial}
              >
                <Link className="has-arrow ai-icon" to="/create-product">
                  {path === "/create-product" ? (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/create-product-active.svg"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/create-product.svg"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text"> Create Product </span>
                </Link>
              </li>
              <li
                className={`${path === "/inventory" ? "mm-active" : ""}`}
                // onClick={productutorial}
                // onClick={() => setShowTutorialModal(true)}
              >
                <Link className="has-arrow ai-icon" to="/inventory">
                  {path === "/inventory" ? (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/product-active.svg"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/product-list.svg"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text"> Products List </span>
                </Link>
              </li>
              <li
                className={`${path === "/Shipping" ? "mm-active" : ""}`}
                onClick={shiptutorial}
              >
                <Link className="has-arrow ai-icon" to="/Shipping">
                  {path === "/Shipping" ? (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/shipping-active.svg"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/shipping-gray.svg"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text">Shipping</span>
                </Link>
              </li>

              <li
                className={`${path === "/shiping-history" ? "mm-active" : ""}`}
                // onClick={IsShipHistoryTunShow}
                // onClick={shiptutorial}
              >
                <Link className="has-arrow ai-icon" to="/shiping-history">
                  {path === "/shiping-history" ? (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/shipping-hist-active.svg"
                      alt="icon"
                    />
                  ) : (
                    <img
                      className="sidebar-menu-icon"
                      src="/images/icons/shipping-history.svg"
                      alt="icon"
                    />
                  )}
                  <span className="nav-text"> Shipping History</span>
                </Link>
              </li>
            </MM>
            <div className="copyright">
              <p>
                {/* <strong>Innap Hotel Admin</strong> © 2021 All Rights Reserved */}
              </p>
              <p className="fs-12">
                <span className="heart"></span>
              </p>
            </div>
          </div>

          <div className="sidebar-bottom-wrapper small-screen-bottom-wrapper">
            <div className="sidebar-explorer-logo-wrap">
              <div className="explorer-left-img">
                <img
                  onClick={() => setShowExTutorialModal(true)}
                  className="hyper-big-logo"
                  src="/images/icons/explorer-logo.png"
                  alt="logo"
                />

                <img
                  onClick={() => setShowExTutorialModal(true)}
                  className="hyper-small-logo"
                  src="/images/icons/hyper-small.png"
                  alt="logo"
                />
              </div>
              <div className="explorer-right-img">
                <img
                  onClick={() => setShowExTutorialModal(true)}
                  src="/images/icons/explorer-arrow.svg"
                  alt="logo"
                />
              </div>
            </div>

            <div className="sidebar-logout-wrapper">
              <div className="sidebar-logout-wrap">
                <svg
                  onClick={onLogout}
                  id="Logout"
                  xmlns="http://www.w3.org/2000/svg"
                  width="41"
                  height="41"
                  viewBox="0 0 41 41"
                >
                  <rect
                    id="Rectangle_906"
                    data-name="Rectangle 906"
                    width="41"
                    height="41"
                    fill="rgba(255,255,255,0)"
                  />
                  <rect
                    id="vuesax_bold_logout"
                    data-name="vuesax/bold/logout"
                    width="30"
                    height="30"
                    transform="translate(37 35) rotate(-180)"
                    fill="none"
                  />
                  <g
                    id="vuesax_bold_logout-2"
                    data-name="vuesax/bold/logout"
                    transform="translate(37.479 35.479) rotate(-180)"
                  >
                    <g id="logout-2" data-name="logout">
                      <path
                        id="Vector"
                        d="M16.757,6.708V19.091c0,4.128-2.58,6.708-6.708,6.708H6.708C2.58,25.8,0,23.219,0,19.091V13.867H8.062a.967.967,0,0,0,0-1.935H0V6.708C0,2.58,2.58,0,6.708,0h3.354C14.19,0,16.769,2.58,16.757,6.708Z"
                        transform="translate(11.61 2.58)"
                        fill="#afafaf"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M3.3,4.318H9.026V6.253H3.3l2.67,2.67A.967.967,0,1,1,4.6,10.291L.281,5.969a.973.973,0,0,1,0-1.367L4.6.281a.973.973,0,0,1,1.367,0,.936.936,0,0,1,.284.684.957.957,0,0,1-.284.684L3.3,4.318Z"
                        transform="translate(2.583 10.194)"
                        fill="#afafaf"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0H30.959V30.959H0Z"
                        transform="translate(30.959 30.959) rotate(180)"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>

                <p onClick={onLogout}> Logout </p>
              </div>
            </div>
          </div>
          <div className="sidebar-bottom-wrapper desktop-sidebar-bottom">
            <div className="sidebar-logout-wrap1s">
              <svg
                onClick={onLogout}
                id="Logout"
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="41"
                viewBox="0 0 41 41"
              >
                <rect
                  id="Rectangle_906"
                  data-name="Rectangle 906"
                  width="41"
                  height="41"
                  fill="rgba(255,255,255,0)"
                />
                <rect
                  id="vuesax_bold_logout"
                  data-name="vuesax/bold/logout"
                  width="30"
                  height="30"
                  transform="translate(37 35) rotate(-180)"
                  fill="none"
                />
                <g
                  id="vuesax_bold_logout-2"
                  data-name="vuesax/bold/logout"
                  transform="translate(37.479 35.479) rotate(-180)"
                >
                  <g id="logout-2" data-name="logout">
                    <path
                      id="Vector"
                      d="M16.757,6.708V19.091c0,4.128-2.58,6.708-6.708,6.708H6.708C2.58,25.8,0,23.219,0,19.091V13.867H8.062a.967.967,0,0,0,0-1.935H0V6.708C0,2.58,2.58,0,6.708,0h3.354C14.19,0,16.769,2.58,16.757,6.708Z"
                      transform="translate(11.61 2.58)"
                      fill="#afafaf"
                    />
                    <path
                      id="Vector-2"
                      data-name="Vector"
                      d="M3.3,4.318H9.026V6.253H3.3l2.67,2.67A.967.967,0,1,1,4.6,10.291L.281,5.969a.973.973,0,0,1,0-1.367L4.6.281a.973.973,0,0,1,1.367,0,.936.936,0,0,1,.284.684.957.957,0,0,1-.284.684L3.3,4.318Z"
                      transform="translate(2.583 10.194)"
                      fill="#afafaf"
                    />
                    <path
                      id="Vector-3"
                      data-name="Vector"
                      d="M0,0H30.959V30.959H0Z"
                      transform="translate(30.959 30.959) rotate(180)"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                </g>
              </svg>

              <p onClick={onLogout}> Logout </p>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
};

// export default SideBar;
export default withRouter(SideBar);
