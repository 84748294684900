import React, { useEffect, useRef, useState } from "react";

const LoginTabs = () => {
  const [activeTab, setActiveTab] = useState("");

  const tabContentRef = useRef(null);

  const openCity = (cityName) => {
    setActiveTab(cityName);
  };

  const clearActiveTab = () => {
    setActiveTab(null); // Set activeTab to null to clear the active tab
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tabContentRef.current &&
        !tabContentRef.current.contains(event.target)
      ) {
        setActiveTab(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="tab-main-wrapper" onMouseLeave={clearActiveTab}>
        <div className="tab">
          <button
            className={`tablinks ${
              activeTab === "distributor" ? "active" : ""
            }`}
            onMouseOver={() => openCity("distributor")}
          >
            <img
              src={
                activeTab === "distributor"
                  ? "/images/icons/dist-active-logo.png"
                  : "/images/icons/dist-logo.png"
              }
              alt="logo"
            />
          </button>
          <button
            className={`tablinks ${activeTab === "wholesaler" ? "active" : ""}`}
            onMouseOver={() => openCity("wholesaler")}
          >
            <img
              src={
                activeTab === "wholesaler"
                  ? "/images/icons/whole-active-logo.png"
                  : "/images/icons/whole-logo.png"
              }
              alt="logo"
            />
          </button>
          <button
            className={`tablinks ${activeTab === "pharma" ? "active" : ""}`}
            onMouseOver={() => openCity("pharma")}
          >
            <img
              src={
                activeTab === "pharma"
                  ? "/images/icons/pharmacy-active-logo.png"
                  : "/images/icons/pharma-logo.png"
              }
              alt="logo"
            />
          </button>
        </div>

        <div
          id="distributor"
          className={`tabcontent ${
            activeTab === "distributor" ? "active" : ""
          }`}
        >
          <div className="tab-content-wrapper">
            <img src="/images/icons/distributor-logo.png" alt="logo" />
            <p> Welcome to the Distributor ERP </p>
            <button>
              <a
                href="https://hyperledgerdistributor.gyan.solutions/login"
                target="_blank"
              >
                Login
              </a>
            </button>
          </div>
        </div>

        <div
          id="wholesaler"
          className={`tabcontent ${activeTab === "wholesaler" ? "active" : ""}`}
        >
          <div className="tab-content-wrapper">
            <img src="/images/icons/wholesaler-logo.png" alt="logo" />
            <p> Welcome to the Wholesaler ERP </p>
            <button>
              <a
                href="https://hyperledgerwholesaler.gyan.solutions/login"
                target="_blank"
              >
                Login
              </a>
            </button>
          </div>
        </div>

        <div
          id="pharma"
          className={`tabcontent ${activeTab === "pharma" ? "active" : ""}`}
        >
          <div className="tab-content-wrapper">
            <img src="/images/icons/pharacy-logo.png" alt="logo" />
            <p> Welcome to the Pharma ERP </p>
            <button>
              <a
                href="https://hyperledgerpharmacy.gyan.solutions/login"
                target="_blank"
              >
                Login
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginTabs;
