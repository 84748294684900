import React, { useState, Fragment, useEffect, useRef } from "react";
import arrow from "../../../images/down-arrow.png";
import { serviceGet, servicePost } from "../../../services/api";
import { useHistory } from "react-router-dom";
import DatePicker from "react-date-picker";
import ProductTutModal from "../Modals/ProductTutModal";
import GyanConsulting from "./GyanConsulting";

const CreateProduct = () => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [productId, setProductId] = useState();
  const [checkProduct, setCheckProduct] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Select Products");
  const productVal = selected == "Select Products" ? "" : selected;
  const [productValue, setProductValue] = useState("");
  const product = productVal == "" ? productValue : productVal;

  const [value, onChange] = useState("");

  const [productData, setProductData] = useState({
    manu: "",
    serial: "",
    gtin: "",
    sku: "",
    lot: "",
    amount: null,
  });

  const history = useHistory();
  const refOne = useRef(null);
  const today = new Date().toISOString().split("T")[0];
  // Access Dom and When Click Outside Close DropDowns...
  useEffect(() => {
    const hideOnClickOutside = (e) => {
      if (refOne.current && !refOne.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("click", hideOnClickOutside);
    return () => {
      document.removeEventListener("click", hideOnClickOutside);
    };
  }, []);

  const { manu, serial, gtin, sku, lot, amount } = productData;
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setProductData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  const uniqueProducts = products?.reduce((acc, current) => {
    if (!acc[current.product]) {
      acc[current.product] = current;
    }
    return acc;
  }, {});

  const uniqueProduct = Object.values(uniqueProducts);

  // get all products
  const getAllProducts = async () => {
    try {
      const allProducts = await serviceGet("api/allproducts");
      setProducts(allProducts);
      setCheckProduct(allProducts?.length > 0 ? true : false);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  // get Product by ProductId
  const getSelectedProduct = async () => {
    try {
      const singleProduct = await serviceGet(`api/product/${productId}`);
      setSelectedProduct(singleProduct);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getSelectedProduct();
  }, [productId]);

  // if product already exists
  useEffect(() => {
    if (selectedProduct) {
      setProductData({
        manu: !selectedProduct.manu ? "" : selectedProduct.manu,
        serial: !selectedProduct.serial ? "" : selectedProduct.serial,
        gtin: !selectedProduct.gtin ? "" : selectedProduct.gtin,
        sku: !selectedProduct.sku ? "" : selectedProduct.sku,
      });
    }
  }, [selectedProduct]);

  // Create product
  const createProduct = async (e) => {
    e.preventDefault();
    if (
      product == "" ||
      manu == "" ||
      serial == "" ||
      gtin == "" ||
      sku == "" ||
      lot == "" ||
      lot == undefined ||
      amount == "" ||
      amount == undefined ||
      value == ""
    ) {
      alert("Please fill All fields");
      return;
    }
    try {
      setIsLoading(true);
      const convertdateformat = new Date(value);
      const convertedDate = convertdateformat.toISOString().slice(0, 10);
      const data = await servicePost("api/createproduct", {
        product: productValue == "" ? productVal : productValue,
        manu: manu,
        serial: serial,
        gtin: gtin,
        sku: sku,
        lot: lot,
        amount: amount,
        expireDate: convertedDate,
        owner: "MANUFACTURE",
      });

      if (data) {
        history.push("/inventory");
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.status === 400) {
        alert(err.response.data.message);
      } else {
        alert("Something went wrong");
      }
    }
  };

  /// HandleKeyPress
  function handleKeyPress(event) {
    const regex = /^[0-9\b]+$/; // allows only numbers and backspace
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  // hadleKey allow only Char
  function handleKeyCharPress(event) {
    const regex = /^[a-zA-Z ]+$/; // allows only characters and space
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  // Increment and Decrement Quantity
  const incrementAmount = () => {
    setProductData((prevData) => ({
      ...prevData,
      amount: Number(prevData.amount) + 1,
    }));
  };

  const decrementAmount = () => {
    if (productData.amount > 0) {
      setProductData((prevData) => ({
        ...prevData,
        amount: Number(prevData.amount) - 1,
      }));
    }
  };

  return (
    <>
      <ProductTutModal
        show={showTutorialModal}
        onHide={() => setShowTutorialModal(false)}
      />
      <div className="create-product-main-wraaper">
        <div className="header-main-wrapper header-desktop-main-wrapper">
          <div className="header-main-wrap">
            <div className="header-logo">
              <img src="/images/icons/create-icon.png" alt="icon" />
              <h3> Create Product </h3>
            </div>

            <div className="create-new-product-btn create-new-destop-btn">
              {products?.length === 0 ? (
                ""
              ) : checkProduct ? (
                <button
                  className="create-new-btn"
                  onClick={() => {
                    setCheckProduct(false);
                    setSelected("Select Products");
                    setSelectedProduct("");
                    setProductValue("");
                    setProductId();
                    setProductData({
                      manu: "",
                      serial: "",
                      gtin: "",
                      sku: "",
                      lot: "",
                      amount: "",
                    });
                  }}
                >
                  <img src="/images/icons/plus-white.svg" alt="icon" />
                  Create New
                </button>
              ) : (
                <button
                  className="create-new-btn"
                  onClick={() => {
                    setCheckProduct(true);
                    setSelected("Select Products");
                    setSelectedProduct("");
                    setProductValue("");
                    setProductId();
                    setProductData({
                      manu: "",
                      serial: "",
                      gtin: "",
                      sku: "",
                      lot: "",
                      amount: "",
                    });
                  }}
                >
                  Select Product
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="header-main-wrapper header-mobile-main-wrapper">
          <div className="header-main-wrap">
            <div className="header-logo">
              <img src="/images/icons/create-icon.png" alt="icon" />
              <h3> Create Product </h3>
            </div>
          </div>
          <div className="mobile-project-main-logo">
            <img src="/images/icons/manu-small-logo.png" alt="logo" />
          </div>
        </div>

        <div className="mobile-create-tutorial-wrap">
          <div className="create-new-product-btn">
            {products?.length === 0 ? (
              ""
            ) : checkProduct ? (
              <button
                className="create-new-btn"
                onClick={() => {
                  setCheckProduct(false);
                  setSelected("Select Products");
                  setSelectedProduct("");
                  setProductValue("");
                  setProductId();
                  setProductData({
                    manu: "",
                    serial: "",
                    gtin: "",
                    sku: "",
                    lot: "",
                    amount: "",
                  });
                }}
              >
                <img src="/images/icons/plus-white.svg" alt="icon" />
                Create New
              </button>
            ) : (
              <button
                className="create-new-btn"
                onClick={() => {
                  setCheckProduct(true);
                  setSelected("Select Products");
                  setSelectedProduct("");
                  setProductValue("");
                  setProductId();
                  setProductData({
                    manu: "",
                    serial: "",
                    gtin: "",
                    sku: "",
                    lot: "",
                    amount: "",
                  });
                }}
              >
                Select Product
              </button>
            )}
          </div>

          <div className="tutorial-wrap-btn">
            <img
              onClick={() => setShowTutorialModal(true)}
              src="/images/icons/tutorial-icon.png"
              alt="icon"
            />
            <p> Tutorials </p>
          </div>
        </div>

        <div className="create-product-main-wrap">
          <div className="create-product-left-wrap">
            <div className="create-product-left">
              <div className="create-product-left-img">
                <img src="/images/create-product.png" alt="img" />
              </div>
              <div className="create-product-left-contant">
                {/* <h2> Welcome</h2>
                <h3> to the Product Creation Page </h3> */}

                <p>
                  You have the capability to create a new product or generate a
                  new lot for an existing product using the Hyperledger Fabric
                  blockchain. This process safeguards the authenticity of
                  product information, ensuring its permanence and the
                  unalterable integrity of data.
                </p>

                <div className="toutorial-icon-wrap">
                  <img
                    onClick={() => setShowTutorialModal(true)}
                    src="/images/icons/tutorial-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="create-product-right-wrap">
            <div className="card1">
              <div className="card-body">
                <form>
                  <div className="create-product-form row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>Product</label>
                      {!checkProduct ? (
                        <div className="create-product-input">
                          <input
                            name="menu"
                            placeholder="Enter Product Name"
                            className="input-field-redux"
                            onChange={(e) => setProductValue(e.target.value)}
                          />
                          <div className="input-icon-wrap">
                            <img src="/images/icons/input-1.svg" alt="icon" />
                          </div>
                        </div>
                      ) : (
                        <div className="select-box-wrapper create-product-select">
                          <div className="react-dropdown">
                            <div
                              className="react-dropdown-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsActive(!isActive);
                              }}
                            >
                              <div className="selected-item">
                                <p>
                                  <div className="input-icon-wrap">
                                    <img
                                      src="/images/icons/input-1.svg"
                                      alt="icon"
                                    />
                                  </div>
                                  {selected}
                                </p>
                              </div>

                              <img
                                className={
                                  isActive
                                    ? "dropdown-down-arrow rotate-arrow"
                                    : "dropdown-down-arrow"
                                }
                                src={arrow}
                                alt="arrow"
                              />
                            </div>
                            {isActive && (
                              <div className="dropdown-content" ref={refOne}>
                                {uniqueProduct &&
                                  uniqueProduct.map((option, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <div
                                          onClick={(e) => {
                                            setSelected(option.product);
                                            setProductId(option._id);
                                            setIsActive(false);
                                          }}
                                          className="dropdown-item"
                                        >
                                          <p> {option.product} </p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Lot Number
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="lot"
                          className="input-field-redux"
                          type="number"
                          placeholder="Enter Lot Number"
                          onKeyPress={handleKeyPress}
                          onChange={onChangeHandler}
                          value={lot}
                          min={0}
                        />
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-1.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Manufacturer
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="manu"
                          className="input-field-redux"
                          onChange={onChangeHandler}
                          onKeyPress={handleKeyCharPress}
                          placeholder="Enter Manufacturer Name"
                          value={manu}
                          disabled={selectedProduct}
                        />
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-3.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Serial No.
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="serial"
                          className="input-field-redux"
                          placeholder="___________"
                          onChange={onChangeHandler}
                          value={serial}
                          disabled={selectedProduct}
                        />

                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-4.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        SKU Number
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="sku"
                          className="input-field-redux"
                          placeholder="Enter sku number"
                          onChange={onChangeHandler}
                          value={sku}
                          disabled={selectedProduct}
                        />
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-5.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Quantity
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input quantity-input-wrap">
                        <input
                          name="amount"
                          className="input-field-redux"
                          type="number"
                          onKeyPress={handleKeyPress}
                          onChange={onChangeHandler}
                          value={amount}
                          min={0}
                        />
                        <div className="increment-icon">
                          <img
                            onClick={incrementAmount}
                            src="/images/icons/increment-icon.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="decrement-icon">
                          <img
                            onClick={decrementAmount}
                            src="/images/icons/decrement-icon.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-6.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        GTIN#
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="gtin"
                          className="input-field-redux"
                          placeholder="Fill GTIN number"
                          onChange={onChangeHandler}
                          value={gtin}
                          disabled={selectedProduct}
                        />
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-7.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Expire date
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          type="date"
                          className="expire-date-input"
                          id="dateInput"
                          min={today}
                          onChange={(e) => onChange(e.target.value)}
                        />
                        {/* <div className="input-icon-wrap">
                          <img
                            id="customDateIcon"
                            src="/images/icons/expire-date-icon.svg"
                            alt="icon"
                          />
                        </div> */}
                      </div>

                      {/* <DatePicker
                        onChange={onChange}
                        value={value}
                        minDate={new Date()}
                      /> */}
                    </div>
                    <div className="col-sm-12 text-center tutorail-button-wrapper">
                      <button
                        className="create-product-btn"
                        onClick={createProduct}
                        disabled={isLoading}
                      >
                        {isLoading ? "Processing..." : "Proceed"}
                      </button>

                      <div className="tutorial-wrap-btn tutorial-tab-etc">
                        <img
                          onClick={() => setShowTutorialModal(true)}
                          src="/images/icons/tutorial-icon.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GyanConsulting />
    </>
  );
};

export default CreateProduct;
